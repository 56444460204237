import styled from "styled-components";
import NavigationItem from "./NavigationItem";
import ThemeToggle from "../../ThemeToggle";

const Wrapper = styled.div`
	width: 314px;
	min-width: 314px;
	height: 100%;
	min-height: 783px;
	background-image: ${({ theme }) => theme.sidebarBG};
	border-bottom-right-radius: 30px;
	border-top-right-radius: 30px;
	padding-left: 60px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	clip-path: path(
		"M-34 30C-34 13.4315 -20.5685 0 -4 0H250C266.569 0 280 13.4315 280 30V686.757C280 700.439 270.742 712.388 257.494 715.806L3.49353 781.328C-15.4947 786.226 -34 771.889 -34 752.279V30Z"
	);

	@media (max-width: 991px) {
		position: absolute;
		transform: translateX(-330px);
	}
`;

const List = styled.ul`
	margin: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 22px 0;
	flex: 1;
`;

const Sidebar = (props) => {
	return (
		<Wrapper>
			<List>
				<NavigationItem to={"/dao/governance"} type={"governance"} title={"Governance"} />
				<NavigationItem to={"/dao/farming"} type={"farming"} title={"Farming"} />
				<NavigationItem to={"/dao/amm"} type={"amm"} title={"AMM"} />
				<NavigationItem to={"/dao/buy-crypto"} type={"buyCrypto"} title={"Buy Crypto"} />
				<NavigationItem to={"/dao/bridge"} type={"bridge"} title={"Bridge"} />
			</List>
			<ThemeToggle />
		</Wrapper>
	);
};

export default Sidebar;
