import styled from "styled-components";
import { useRouteMatch } from 'react-router-dom'

import Header from "../Header/Shopping";
import Footer from "../Footer";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
    align-items: stretch;
	min-height: 100vh;
`;

const ControlOverflowWrapper = styled(Wrapper)`
  overflow: ${({ hideOverflow }) => hideOverflow ? 'hidden' : 'initial'};
`

const Content = styled.div`
	display: flex;
	flex: 1;
    flex-direction: column;
    align-items: stretch;
`;


const ShoppingLayout = props => {
    const router = useRouteMatch()
    let Wrap = Wrapper;
    if(router.path === '/' && router.isExact) {
        Wrap = ControlOverflowWrapper
    }
    return (
        <Wrap hideOverflow={router.path === '/'}>
            <div className="container flex-grow-1">
                <Header />
                <Content>
                    {props.children}
                </Content>
            </div>
            <Footer/>
        </Wrap>
    )
}

export default ShoppingLayout;