import React from "react";

const MoonIcon = ({ size, fill, color }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width={size} height={size}>
			<path
				opacity="0.5"
				d="M14.7162 8.02709C14.6079 7.9376 14.4767 7.88032 14.3375 7.86175C14.1983 7.84319 14.0566 7.8641 13.9287 7.92209C13.1356 8.28501 12.2733 8.47179 11.4012 8.46959C9.78782 8.46764 8.24066 7.82792 7.09704 6.68992C5.95341 5.55191 5.30608 4.00792 5.29619 2.39459C5.29961 1.88897 5.36254 1.3855 5.48369 0.894591C5.50939 0.763745 5.49975 0.628411 5.45575 0.502532C5.41175 0.376652 5.33499 0.26478 5.23337 0.17844C5.13175 0.0920996 5.00894 0.0344144 4.87761 0.0113269C4.74628 -0.0117605 4.61116 0.000584791 4.48619 0.047091C3.31043 0.575772 2.28836 1.39466 1.516 2.42683C0.743645 3.459 0.246345 4.67056 0.0708223 5.94771C-0.104701 7.22485 0.0473147 8.52565 0.512588 9.72791C0.977861 10.9302 1.74112 11.9944 2.73066 12.8207C3.7202 13.647 4.90354 14.2081 6.16952 14.4515C7.4355 14.6948 8.74255 14.6123 9.9679 14.2118C11.1932 13.8112 12.2967 13.1058 13.1745 12.1617C14.0523 11.2176 14.6757 10.0658 14.9862 8.81459C15.024 8.67134 15.0185 8.52009 14.9705 8.37994C14.9224 8.23979 14.8339 8.11701 14.7162 8.02709ZM7.59119 13.0446C6.3325 13.0357 5.10734 12.638 4.08343 11.9059C3.05952 11.1738 2.28693 10.1431 1.87145 8.95494C1.45596 7.76678 1.41789 6.47923 1.76244 5.26859C2.10699 4.05796 2.81733 2.98342 3.79619 2.19209V2.39459C3.79817 4.41095 4.60005 6.34416 6.02583 7.76995C7.45162 9.19573 9.38482 9.99761 11.4012 9.99959C11.9306 10.0015 12.4587 9.94621 12.9762 9.83459C12.4586 10.8137 11.6835 11.633 10.7346 12.204C9.78557 12.7749 8.6987 13.076 7.59119 13.0746V13.0446Z"
				fill={fill || "currentColor"}
			/>
		</svg>
	);
};

export default MoonIcon;
