import { createReducer } from "@reduxjs/toolkit";
import {deleteAddressFromList, setAddresses, updateAddresses} from "./actions";

const initialState = {
    list: []
}

export default createReducer(initialState, builder =>
    builder
        .addCase(setAddresses, (state, action) => {
            state.list = action.payload;
        })
        .addCase(updateAddresses, (state, action) => {
            state.list = state.list.concat(action.payload);
        })
        .addCase(deleteAddressFromList, (state, action) => {
            state.list = state.list.filter(item => item?._id !== action.payload);
        })
)