import MetamaskGuideline from "./Metamask";
import TrezorGuideline from "./Trezor";
import LedgerGuideline from "./Ledger";
import TrustWalletGuideline from "./TrustWallet";

export default {
	metamask: MetamaskGuideline,
	trezor: TrezorGuideline,
	ledger: LedgerGuideline,
	trustWallet: TrustWalletGuideline,
};
