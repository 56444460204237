import instance from "./instance";

class Api {
    constructor() {
        this.req = instance;
    }

    // Admin routes
    adminLogin(body) {
        return this.req.post('admin/login', body);
    }

    adminLogout() {
        return this.req.get('admin/logout');
    }


    // Vouchers
    getAllVouchers(config = {}) {
        return this.req.get('voucher', config)
    }

    getVoucher(id, config = {}) {
        return this.req.get(`voucher/${id}`, config);
    }

    createVoucher(body, config = {}) {
        return this.req.post(`voucher`, body, config);
    }

    updateVoucher(id, body, config = {}) {
        return this.req.patch(`voucher/${id}`, body, config);
    }

    deleteVoucher(id, config = {}) {
        return this.req.delete(`voucher/${id}`, config);
    }

    // User wallet routes
    checkUserExists(address = '') {
        return this.req.get(`user`, {
            params: {
                walletAddress: address?.toLowerCase(),
            }
        })
    }

    getUser(address = '', config = {}) {
        return this.req.get(`user/${address?.toLowerCase()}`, config);
    }

    updateUser(address = '', body, config = {}) {
        return this.req.patch(`user/${address?.toLowerCase()}`, body, config);
    }

    getUserLimits(address = {}, config = {}) {
        return this.req.get(`user/${address?.toLowerCase()}/limits`, config);
    }

    signUpUser(body) {
        return this.req.post('user', body);
    }

    authUser(body) {
        return this.req.post('auth', body);
    }

    // User Address
    getAllAddresses(config = {}) {
        return this.req.get('address', config)
    }

    getAddress(id, config = {}) {
        return this.req.get(`address/${id}`, config);
    }

    createAddress(body, config = {}) {
        return this.req.post(`address`, body, config);
    }

    updateAddress(id, body, config = {}) {
        return this.req.patch(`address/${id}`, body, config);
    }

    deleteAddress(id, config = {}) {
        return this.req.delete(`address/${id}`, config);
    }

    // Shop
    search(params) {
        return this.req.get('shop/search', {
            params,
        })
    }

    product(params) {
        return this.req.get('shop/product', {
            params
        })
    }

    home() {
        return this.req.get('shop')
    }

    // Cart
    getCart(config = {}) {
        return this.req.get('cart', config);
    }

    addProduct(body, config = {}) {
        return this.req.post('cart', body, config)
    }

    updateProduct(body, config = {}) {
        return this.req.patch('cart', body, config);
    }

    deleteProduct(id, config = {}) {
        return this.req.delete(`cart/${id}`, config);
    }

    updateCartAddress(body, config = {}) {
        return this.req.patch('cart/address', body, config);
    }

    updateCartShipping(body, config = {}) {
        return this.req.patch('cart/shipping', body, config);
    }

    checkout(body, config = {}) {
        return this.req.post('cart/checkout', body, config);
    }

    applyVoucher(body, config = {}) {
        return this.req.post('cart/voucher', body, config);
    }


    // Admin orders
    getAllOrders(config = {}) {
        return this.req.get('order', config);
    }

    getOrder(id, config = {}) {
        return this.req.get(`order/${id}`, config);
    }

    deleteOrder(id, config = {}) {
        return this.req.delete(`order/${id}`, config);
    }

    updateOrderStatus(id, body, config = {}) {
        return this.req.patch(`order/${id}`, body, config)
    }

    // User orders
    getUserAllOrders(config = {}) {
        return this.req.get(`order/user`, config);
    }

    getUserOrder(id, config = {}) {
        return this.req.get(`order/user/${id}`, config);
    }
}

const api = new Api();

export default api;