import { createReducer } from "@reduxjs/toolkit";
import {amazonUrls} from "../../constants";
import {setAmazonUrl} from "./actions";

const initialState = {
    amazonUrl: amazonUrls.us
}

export default createReducer(initialState, builder =>
    builder
        .addCase(setAmazonUrl, (state, action) => {
            state.amazonUrl = action.payload;
        })
)