import {Redirect, Route, Switch} from "react-router-dom";
import {Suspense, lazy} from "react";

import AdminLoading from "./Loading";
import {useSelector} from "react-redux";

const LoginPage = lazy(() => import('../../pages/Admin/LoginPage'));
const Orders = lazy(() => import('../../pages/Admin/Orders'));
const Vouchers = lazy(() => import('../../pages/Admin/Vouchers'));
const NewVoucher = lazy(() => import('../../pages/Admin/NewVoucher'));
const EditVoucher = lazy(() => import('../../pages/Admin/EditVoucher'));
const OrderDetails = lazy(() => import('../../pages/Admin/OrderDetails'));

const AdminPage = props => {
    const isLoggedIn = useSelector(state => state?.admin?.isLoggedIn);

    return (
        <Suspense fallback={<AdminLoading/>}>
            <Switch>
                <Route path={'/admin/login'} component={LoginPage}/>
                {isLoggedIn && (
                    <Switch>
                        <Route path={'/admin/orders'} component={Orders}/>
                        <Route path={'/admin/order/:id'} component={OrderDetails}/>
                        <Route path={'/admin/vouchers/new'} component={NewVoucher}/>
                        <Route path={'/admin/vouchers/:id/edit'} component={EditVoucher}/>
                        <Route path={'/admin/vouchers'} component={Vouchers}/>
                    </Switch>
                )}
                <Redirect to={"/admin/login"}/>
            </Switch>
        </Suspense>
    )
}

export default AdminPage;