import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Icon from "./Icon";

const Item = styled.li`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 10px 0;
`;

const StyledLink = styled(NavLink)`
	text-decoration: none;
	opacity: 0.3;
	color: ${({ theme }) => theme.text1};
	display: flex;
	align-items: center;
	transition: opacity 0.3s ease;

	&.active {
		opacity: 1;
	}

	&.active .sidebar__title::before {
		transform: translateX(0);
		opacity: 1;
	}
`;

const Title = styled.span`
	position: relative;
	color: #fff;
	height: 56px;
	display: flex;
	align-items: center;
	flex: 1;
	padding: 0 19px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 56px;
		background-image: ${({ theme }) => theme.sidebarActiveBG};
		border-radius: 18px 0 0 18px;
		opacity: 0;
		transform: translateX(45px);
		transition: 0.3s ease all;
	}
`;

const NavigationItem = (props) => {
	return (
		<Item>
			<StyledLink to={props.to} activeClassName={"active"}>
				<Icon type={props.type} />
				<Title className={"sidebar__title"}>{props.title}</Title>
			</StyledLink>
		</Item>
	);
};

export default NavigationItem;
