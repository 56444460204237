import {Redirect, Route, Switch} from "react-router-dom";
import {lazy} from "react";

import Layout from "./components/Global/Layout";

const BuyCrypto = lazy(() => import('./pages/buyCrypto'))
const Bridge = lazy(() => import('./pages/Bridge'))
const Governance = lazy(() => import('./pages/Governance'))
const Proposal = lazy(() => import('./pages/Proposal'))
const CreateProposal = lazy(() => import('./pages/CreateProposal'))
const Farming = lazy(() => import('./pages/Farming'))
const AMM = lazy(() => import('./pages/AMM'))
const Connect = lazy(() => import('./pages/connect'))

const DAORoutes = props => {
    return (
        <Layout>
            <Switch>
                <Route path={"/dao/buy-crypto"} exact component={BuyCrypto} />
                <Route path={"/dao/bridge"} exact component={Bridge} />
                <Route path={"/dao/governance"} exact component={Governance} />
                <Route path={"/dao/governance/proposal/:id"} exact component={Proposal} />
                <Route path={"/dao/governance/create"} exact component={CreateProposal} />
                <Route path={"/dao/farming"} component={Farming} />
                <Route path={"/dao/amm"} component={AMM} />
                <Route path={"/dao/connect"} exact component={Connect} />
                <Redirect to={'/dao/governance'}/>
            </Switch>
        </Layout>
    )
}

export default DAORoutes