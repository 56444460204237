import styled from "styled-components";
import { Row, Col } from 'react-bootstrap';

import GiftImage from '../../../assets/images/payment/successful.png';
import Background from '../../../assets/images/hero/background.png';
import Img from "../../../components/Img";
import {APP_NAME} from "../../../constants";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 120px 99px 56px;
  border-radius: 80px;
  margin-bottom: 83px;
  position: relative;
  background-image: ${({ theme }) => theme.heroBG};
  
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: stretch;
    padding: 10px 20px 16px;
    border-radius: 24px;
    margin-bottom: 32px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: 410px;
  
  
  @media (max-width: 991px) {
    padding-right: 0;
  }
`

const Title = styled.h2`
  font-size: 3.5rem;
  line-height: 4.25rem;
  color: ${({ theme }) => theme.text1};
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 70px;


  @media (max-width: 991px) {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 24px;
  }
`

const GiftImg = styled(Img).attrs(() => ({
    src: GiftImage,
    alt: "Discount"
}))`
  width: 97px;
  height: 97px;

  @media (max-width: 991px) {
    width: 56px;
    height: 56px;
  }
`

const Description = styled.span`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.text1};
  font-weight: 300;


  @media (max-width: 991px) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`

const BackgroundWrapper = styled.div`
  position: relative;
  height: auto;
  margin-bottom: 24px;
  margin-right: -32px;
  
  @media (min-width: 992px) {
    margin: 0;
    position: absolute;
    width: 557px;
    height: 557px;
    top: -59px;
    right: -40px;
    bottom: -20px;
  }
`

const BgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  
`

const BgImage = styled(Img).attrs(() => ({
    src: Background,
    alt: APP_NAME
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
`
const Hero = props => {
    return (
        <Row>
            <Col>
                <Wrapper>
                    <Content>
                        <Title>Shop by your crypto currency wallet</Title>
                        <div className="d-flex align-items-center">
                            <GiftImg/>
                            <Description>
                                and Get a 15% + Discount on Amazon
                            </Description>
                        </div>
                    </Content>
                    <BackgroundWrapper>
                        <BgContainer>
                            <BgImage/>
                        </BgContainer>
                    </BackgroundWrapper>
                </Wrapper>
            </Col>
        </Row>
    )
}

export default Hero;