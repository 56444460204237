import { createReducer } from "@reduxjs/toolkit";

import { updateDarkMode } from "./actions";
import { INITIAL_DARK_MODE } from "../../constants";

export interface preferencesState {
	darkMode: boolean;
}

export const initialState: preferencesState = {
	darkMode: INITIAL_DARK_MODE,
};

export default createReducer(initialState, (builder) => {
	builder.addCase(updateDarkMode, (state, { payload: { userDarkMode } }) => {
		state.darkMode = userDarkMode;
	});
});
