import styled from "styled-components";
import Header from "../Header";
import Sidebar from "../Sidebar";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
`;

const Container = styled.div`
	padding-left: 68px;
	padding-right: 60px;

	@media (max-width: 991px) {
		padding: 0 30px;
	}
`;

const Layout = (props) => {
	return (
		<Wrapper>
			<Header />
			<Content>
				<Sidebar />
				<Container className="container-fluid">{props.children}</Container>
			</Content>
		</Wrapper>
	);
};

export default Layout;
