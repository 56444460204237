import styled from "styled-components";

import Base from "./Base";

import Image1 from "../../assets/guides/metamask/1.png";
import Image2 from "../../assets/guides/metamask/2.png";
import Image3 from "../../assets/guides/metamask/3.png";
import Image4 from "../../assets/guides/metamask/4.png";
import Image5 from "../../assets/guides/metamask/5.png";

const Ol = styled.ol`
	padding-left: 1rem;
`;

const UL = styled.ul`
	padding-left: 2rem;
	list-style: ${({ listStyle }) => listStyle || "disc"};
`;

const Li = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
`;

const Title = styled.span`
	font-size: 1rem;
	color: ${({ theme }) => theme.text1};
	margin-bottom: 1rem;
`;

const InnerContent = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	margin-left: -1rem;
	margin-bottom: 1.5rem;
`;

const Img = styled.img`
	border-radius: 36px;
	max-width: 100%;

	@media (max-width: 991px) {
		max-width: 100%;
	}
`;

const InnerTitle = styled.span`
	margin-bottom: 0.75rem;
	display: block;
`;

const OuterLi = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;

const InnerLi = styled.li`
	padding: 0.5rem 0;
`;

const Anchor = styled.a`
	color: ${({ theme }) => theme.text1};
	text-decoration: underline;
	font-weight: 300;

	&:hover {
		color: ${({ theme }) => theme.text2};
	}
`;

const ReferenceBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	min-height: 56px;
	line-height: 56px;
	background-color: ${({ theme }) => theme.elementBG};
	color: ${({ theme }) => theme.text1};
	font-weight: 700;
	font-size: 1rem;
	width: 100%;
`;

const MetamaskGuideline = (props) => {
	return (
		<Base provider={"Metamask"}>
			<Ol>
				<Li>
					<Content>
						<Title>Go to setting page</Title>
						<InnerContent>
							<Img src={Image1} alt="metamask setting page" style={{ zoom: "50%" }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>Add a new network</Title>
						<InnerContent>
							<Img src={Image2} alt="new network" style={{ zoom: "75%" }} />
						</InnerContent>
						<InnerContent>
							<UL>
								<OuterLi>
									<InnerTitle>Testnet</InnerTitle>
									<UL listStyle={"circle"}>
										<InnerLi>
											<Anchor
												href="https://docs.binance.org/smart-chain/developer/rpc.html"
												target={"_blank"}
												rel={"noreferrer noopener"}
											>
												RPC URLs
											</Anchor>
										</InnerLi>
										<InnerLi>ChainID: 0x61, 97 in decimal (if 97 doesn’t work, try 0x61)</InnerLi>
										<InnerLi>Symbol: BNB</InnerLi>
										<InnerLi>Block Explorer: https://testnet.bscscan.com</InnerLi>
									</UL>
								</OuterLi>
								<OuterLi>
									<InnerTitle>MainNet</InnerTitle>
									<UL listStyle={"circle"}>
										<InnerLi>
											<Anchor
												href="https://docs.binance.org/smart-chain/developer/rpc.html"
												target={"_blank"}
												rel={"noreferrer noopener"}
											>
												RPC URLs
											</Anchor>
										</InnerLi>
										<InnerLi>ChainID: 0x38, 56 in decimal (if 56 doesn’t work, try 0x38)</InnerLi>
										<InnerLi>Symbol: BNB</InnerLi>
										<InnerLi>Block Explorer: https://bscscan.com</InnerLi>
									</UL>
								</OuterLi>
							</UL>
						</InnerContent>
					</Content>
				</Li>

				<Li>
					<Content>
						<Title>Claim some testnet token to your account Click on your address for copy</Title>
						<InnerContent>
							<Img src={Image3} alt="claim bnb" style={{ zoom: "50%" }} />
						</InnerContent>
					</Content>
				</Li>
				<Li>
					<Content>
						<Title>
							Go to faucet page: https://testnet.binance.org/faucet-smart, then paste your address in the
							box and click on “Give me BNB”
						</Title>
						<InnerContent>
							<Img src={Image4} alt="binance testnet faucet-smart" style={{ maxWidth: "100%" }} />
						</InnerContent>
						<Title>After the transfer transaction is sent, you will see an increase of your balance</Title>
						<InnerContent>
							<Img src={Image5} alt="transaction submitted" style={{ zoom: "50%" }} />
						</InnerContent>
					</Content>
				</Li>
			</Ol>
			<ReferenceBox>
				<span className="pr-2">Source:</span>{" "}
				<Anchor
					href="https://docs.binance.org/smart-chain/wallet/metamask.html"
					target={"_blank"}
					rel={"noreferrer noopener"}
				>
					Binance Smart chain Docs
				</Anchor>
			</ReferenceBox>
		</Base>
	);
};

export default MetamaskGuideline;
