import Partner1 from '../assets/images/partners/partner-1.png';
import Partner2 from '../assets/images/partners/partner-2.png';
import Partner3 from '../assets/images/partners/partner-3.png';
import Partner4 from '../assets/images/partners/partner-4.png';
import Partner5 from '../assets/images/partners/partner-5.png';

export const partners = [
    {
        image: Partner1
    },
    {
        image: Partner2
    },
    {
        image: Partner3
    },
    {
        image: Partner4
    },
    {
        image: Partner5
    },
]

export default partners;