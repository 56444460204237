import styled from "styled-components";

const Wrapper = styled.div`
	pointer-events: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 100vh;

	@media (max-width: 991px) {
		display: none;
		width: 1px;
		height: 1px;
		opacity: 0;
	}
`;

const BG1 = styled.div`
	position: absolute;
	width: 600px;
	height: 600px;
	left: 266px;
	top: 200px;
	background-color: ${({ theme }) => theme.bg1};
	filter: blur(214px);

	@media (max-width: 991px) {
		filter: blur(0);
	}
`;

const BG2 = styled.div`
	position: absolute;
	width: 825px;
	height: 825px;
	left: -393px;
	top: -239px;
	background-color: ${({ theme }) => theme.bg2};
	filter: blur(244px);

	@media (max-width: 991px) {
		filter: blur(0);
	}
`;

const BG3 = styled.div`
	position: absolute;
	width: 755.31px;
	height: 714.67px;
	left: 964px;
	top: 403.08px;
	background-color: ${({ theme }) => theme.bg3};
	filter: blur(244px);
	transform: rotate(-21deg);

	@media (max-width: 991px) {
		filter: blur(0);
	}
`;

const BG4 = styled.div`
	position: absolute;
	width: 638px;
	height: 638px;
	left: 232px;
	top: -169px;
	background-color: ${({ theme }) => theme.bg4};
	filter: blur(244px);

	@media (max-width: 991px) {
		filter: blur(0);
	}
`;

const BodyBG = () => {
	return (
		<Wrapper>
			<BG1 />
			<BG2 />
			<BG3 />
			<BG4 />
		</Wrapper>
	);
};

export default BodyBG;
