import { createReducer } from "@reduxjs/toolkit";
import {deleteVoucherFromList, setVouchers, updateVouchers} from "./actions";

const initialState = {
    vouchers: [],
}

export default createReducer(initialState, builder =>
    builder
        .addCase(setVouchers, (state, action) => {
            state.vouchers = action.payload;
        })
        .addCase(updateVouchers, (state, action) => {
            state.vouchers = state.vouchers.concat(action.payload);
        })
        .addCase(deleteVoucherFromList, (state, action) => {
            state.vouchers = state.vouchers.filter(item => item?._id !== action.payload);
        })
)