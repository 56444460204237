import BG1 from '../assets/images/buySteps/bg1.png'
import BG2 from '../assets/images/buySteps/bg2.png'
import BG3 from '../assets/images/buySteps/bg3.png'

export const steps = [
    {
        title: "Search for product",
        description: "Search for product that you want to buy",
        icon: BG1,
        style: {
            top:'-23px',
            right:'33px'
        }
    },
    {
        title: "Pay with Crypto",
        description: "Your payment is confirmed the same minute in most cases",
        icon: BG2,
        style: {
            top:'-8px',
            right:'30px'
        },
    },
    {
        title: "Receive the product",
        description: "Once your payment is confirmed you will receive your order",
        icon: BG3,
        style: {
            top:'-55px',
            right:'-35px'
        }
    },
]

export default steps;