import { createAction } from '@reduxjs/toolkit';
import api from "../../http/api";
import {toast} from "react-hot-toast";

export const updateLogin = createAction('admin/login', (accessToken, admin) => {
    return {
        payload: {
            accessToken,
            admin,
        }
    }
});

export const updateLogout = createAction('admin/logout');


export const login = (email, password) => {
    return async dispatch => {
        try {
            const res = await api.adminLogin({
                email,
                password,
            })

            if(res && res?.accessToken) {
                toast.success("Logged in successfully");
                dispatch(updateLogin(res?.accessToken, res?.data?.admin));

                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const logout = () => {
    return async dispatch => {
        try {
            const res = await api.adminLogout()
            if(res) {
                toast.success("Logged out successfully");
                dispatch(updateLogout());
            }
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}