export const BST = {
	address: "0x6dB01442EF43e3CA60f3158862DfC6539AE624B2",
	explorer: "https://testnet.bscscan.com/token/0x6db01442ef43e3ca60f3158862dfc6539ae624b2",
	symbol: "BST",
	name: "Binance Chain Token",
	decimals: 18,
};

export const BSTO = {
	address: "0x6dB01442EF43e3CA60f3158862DfC6539AE624B2",
	explorer: "https://kovan.etherscan.io/token/0x6db01442ef43e3ca60f3158862dfc6539ae624b2",
	symbol: "BSTO",
	name: "Binance smart token ethereum",
	decimals: 18,
};
