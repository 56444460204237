import {createAction} from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setUserOrders = createAction('orders/set');
export const updateUserOrders = createAction('order/update');

export const getUserOrders = (params) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }


        try {
            const res = await api.getUserAllOrders({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                },
                params
            })

            if(res) {
                if(params.page === 1) {
                    dispatch(setUserOrders(res?.data?.data));
                } else {
                    dispatch(updateUserOrders(res?.data?.data));
                }
                return res?.results;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");
            return false;
        }
    }
}

export const getUserOrder = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUserOrder(id, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                return res?.data?.order;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}