import { createAction } from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const disconnectWallet = createAction('auth/disconnect');
export const connectWallet = createAction('auth/connect', (accessToken, profile) => {
    return {
        payload: {
            accessToken,
            profile,
        }
    }
});

export const updateProfile = createAction('auth/updateProfile');
export const setLimits = createAction('auth/limits');

export const getProfile = (account) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUser(account?.toLowerCase(), {
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                dispatch(updateProfile(res?.data?.user));
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");
            return false;
        }
    }
}

export const updateProfileAsync = (account, body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.updateUser(account?.toLowerCase(), body, {
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                dispatch(updateProfile(res?.data?.user));
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");
            return false;
        }
    }
}

export const getProfileLimits = (account) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.auth?.accessToken;

        if(!jwt) {
            toast.error("You should sign your wallet to continue");
            return false;
        }

        try {
            const res = await api.getUserLimits(account?.toLowerCase(), {
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            if(res) {
                dispatch(setLimits(res?.data));
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");
            return false;
        }
    }
}