import { Link } from "react-router-dom";
import styled from "styled-components";

import LogoImage from "../../../assets/images/logo.svg";
import { APP_NAME } from "../../../constants";

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none !important;
	position: relative;

	&:focus,
	&:active {
		outline: none;
	}
`;

const Img = styled.img`
	width: 35px;
	height: 25px;
	margin-right: 6px;
`;

const Title = styled.span`
	font-weight: bold;
	font-size: 18px;
	color: ${({ theme }) => theme.text1};
`;

const Logo = (props) => {
	return (
		<StyledLink to={"/"}>
			<Img src={LogoImage} alt={APP_NAME} className={props.hideTitle ? "mr-0" : ""} />
			{!props.hideTitle && <Title className={`d-none d-lg-block`}>{APP_NAME}</Title>}
		</StyledLink>
	);
};

export default Logo;
