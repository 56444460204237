import ShoppingPage from "../../components/Global/Page/shopping";
import Hero from "./components/Hero";
import Steps from "./components/Steps";
import Products from "./components/Products";
import Partners from "./components/Partners";
import Testimonial from "./components/Testimonial";

const ShoppingHome = props => {
    return (
        <ShoppingPage
            breadcrumb={false}
            withSearchbar={true}
        >
            <Hero/>
            <Steps/>
            <Products/>
            <Testimonial/>
            <Partners/>
        </ShoppingPage>
    )
}

export default ShoppingHome