import styled from "styled-components";
import Loading from "../Loading";
import React from "react";

const Container = styled.div`
  background-color: ${({ theme }) => theme.elementBG};
  height: 300px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`


const AdminLoading = props => {
    return (
        <Container>
            <Loading width={"44"} height={"44"} color={"primary"} active={true} id={"admin-loading"} />
        </Container>
    )
}

export default AdminLoading