import {useDispatch, useSelector} from "react-redux";
import Web3 from "web3";
import {connectWallet, disconnectWallet} from "./actions";
import api from "../../http/api";
import {useActiveWeb3React} from "../../hooks";
import {useMemo} from "react";
import {emptyCart} from "../cart/actions";

export const useAuthentication = () => {
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const authenticatedUser = useSelector(state => state.auth.profile);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const handleSignMessage = async (address, nonce) => {
        try {
            const web3 = new Web3(Web3.givenProvider);
            if(!web3) {
                dispatch(disconnectWallet());
                return Promise.reject("You need to sign the message to be able to log in.");
            }
            const signature = await web3?.eth.personal.sign(
                web3.utils.toHex(`Signing wallet address: ${nonce}`),
                address,
                null
            );

            return { address, signature };
        } catch (err) {
            throw new Error(
                'You need to sign the message to be able to log in.'
            );
        }
    }

    const handleAuthorization = async () => {
        if(!account) {
            dispatch(disconnectWallet());
            return false;
        }

        const publicAddress = account?.toLowerCase();
        if(publicAddress === authenticatedUser?.address) {
            return true;
        }

        let connectedAddress;
        let nonce;
        try {
            const userExists = await api.checkUserExists(publicAddress)

            if(!userExists?.data?.result) {
                const signUpRes = await api.signUpUser({
                    address: publicAddress
                })

                if(!signUpRes) {
                    dispatch(disconnectWallet());
                    return false;
                }

                connectedAddress = signUpRes?.data.data.address;
                nonce = signUpRes?.data.data.nonce;
            } else {
                connectedAddress = publicAddress;
                nonce = userExists?.data?.result?.nonce;
            }

            const authBody = await handleSignMessage(connectedAddress, nonce)

            const authRes = await api.authUser(authBody);

            if(authRes) {
                dispatch(connectWallet(authRes?.accessToken, authRes?.data?.user));
            }

            dispatch(emptyCart())
        } catch(e) {
            dispatch(disconnectWallet());
            dispatch(emptyCart())
            return false;
        }
    }

    return useMemo(() => {
        return [isLoggedIn, handleAuthorization];
    }, [isLoggedIn, account])
}