import { createReducer } from "@reduxjs/toolkit";
import {setUserOrders, updateUserOrders} from "./actions";

const initialState = {
    list: [],
}

export default createReducer(initialState, builder =>
    builder
        .addCase(setUserOrders, (state, action) => {
            state.list = action.payload;
        })
        .addCase(updateUserOrders, (state, action) => {
            state.list = state.list.concat(action.payload);
        })
)