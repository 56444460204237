import {Switch, Route } from "react-router-dom";

import ShoppingLayout from "./components/Global/Layout/Shopping";
import AccountPage from "./components/AccountPage";
import AdminPage from "./components/AdminPage";
import {lazy} from "react";
import CartPage from "./components/CartPage";
import ShoppingHome from "./pages/ShoppingHome";
import Connect from "./pages/shoppingConnect";

const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const SearchPage = lazy(() => import('./pages/Search'));
const ProductPage = lazy(() => import('./pages/ProductPage'));

const ShoppingRoutes = props => {
    return (
        <ShoppingLayout>
            <Switch>
                <Route path={'/product/:productId'} component={ProductPage}/>
                <Route path={'/search'} component={SearchPage}/>
                <Route path={'/order/:orderId'} component={OrderDetails}/>
                <Route path={'/cart'} component={CartPage}/>
                <Route path={'/account'} component={AccountPage}/>
                <Route path={'/admin'} component={AdminPage}/>
                <Route path={"/connect"} exact component={Connect} />
                <Route path={'/'} component={ShoppingHome} />
            </Switch>
        </ShoppingLayout>
    )
}

export default ShoppingRoutes;