import styled from "styled-components";
import {Row, Col} from 'react-bootstrap';
import ProductCard from "../../../components/ProductCard";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {getHome} from "../../../states/shop/actions";


const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`

const InnerCol = styled(Col)`
  margin-bottom: 28px;
`

const InnerRow = styled(Row)`
  margin-right: -14px;
  margin-left: -14px;
  margin-bottom: 36px;

  & > ${InnerCol} {
    padding-right: 14px;
    padding-left: 14px;
  }
`


const Products = props => {
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        getSections();
    }, [dispatch])

    const getSections = async () => {
        setLoading(true);
        const res = await dispatch(getHome());

        if(res) {
            setSections(res?.data);
        }
        setLoading(false);
    }

    return (
        <Row>
            {(loading || sections?.featured) && (
                <>
                    <Col xs={12}>
                        <Title>Featured Products</Title>
                    </Col>
                    <Col xs={12}>
                        <InnerRow>
                            {loading ? [...Array(4)].map((item, _i) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`featured-loading-${_i}`}
                                    >
                                        <ProductCard loading={true}/>
                                    </InnerCol>
                                )
                            }) : sections?.featured?.slice(0, 4)?.map((item) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`featured-${item?.asin}`}
                                    >
                                        <ProductCard
                                            item={item}
                                        />
                                    </InnerCol>
                                )
                            })}
                        </InnerRow>
                    </Col>
                </>
            )}
            {(loading || sections?.newest) && (
                <>
                    <Col xs={12}>
                        <Title>Newest Products</Title>
                    </Col>
                    <Col xs={12}>
                        <InnerRow>
                            {loading ? [...Array(4)].map((item, _i) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`newest-loading-${_i}`}
                                    >
                                        <ProductCard loading={true}/>
                                    </InnerCol>
                                )
                            }) : sections?.newest?.slice(0, 4)?.map((item) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`newest-${item?.asin}`}
                                    >
                                        <ProductCard
                                            item={item}
                                        />
                                    </InnerCol>
                                )
                            })}
                        </InnerRow>
                    </Col>
                </>
            )}

            {(loading || sections?.computerComponents) && (
                <>
                    <Col xs={12}>
                        <Title>Computer components</Title>
                    </Col>
                    <Col xs={12}>
                        <InnerRow>
                            {loading ? [...Array(4)].map((item, _i) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`computerComponents-loading-${_i}`}
                                    >
                                        <ProductCard loading={true}/>
                                    </InnerCol>
                                )
                            }) : sections?.computerComponents?.slice(0, 4)?.map((item) => {
                                return (
                                    <InnerCol
                                        xs={12}
                                        md={6}
                                        lg={3}
                                        key={`computerComponents-${item?.asin}`}
                                    >
                                        <ProductCard
                                            item={item}
                                        />
                                    </InnerCol>
                                )
                            })}
                        </InnerRow>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default Products;