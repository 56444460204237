import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {SkeletonTheme} from "react-loading-skeleton";

import { FixedGlobalStyle, ThemedGlobalStyle } from "./theme";
import Routes from "./Routes";
import BodyBG from "./components/Global/BG";
import useTheme from "./hooks/useTheme";

import ApplicationUpdater from "./states/application/updater";
import ListsUpdater from "./states/lists/updater";
import MulticallUpdater from "./states/multicall/updater";
import TransactionUpdater from "./states/transactions/updater";
import AuthUpdater from './states/auth/updater';
import CartUpdater from './states/cart/updater';
import Web3ReactManager from "./components/Web3ReactManager";

const App = () => {
	const theme = useTheme();

	return (
		<Web3ReactManager>
			<SkeletonTheme
				color={'transparent'}
				highlightColor={theme.elementBG}
			>
				<AuthUpdater/>
				<CartUpdater/>
				<ListsUpdater />
				<ApplicationUpdater />
				<TransactionUpdater />
				<MulticallUpdater />
				<Toaster
					position="bottom-left"
					toastOptions={{
						className: "",
						style: {
							background: theme.modalBG,
							color: theme.text1,
							zIndex: 9999999,
							boxShadow: "initial",
							borderRadius: "12px",
							border: `1px solid ${theme.text3}`,
						},
						duration: 5000,
					}}
				/>
				<FixedGlobalStyle />
				<BodyBG />
				<ThemedGlobalStyle />
				<Router>
					<Routes/>
				</Router>
			</SkeletonTheme>
		</Web3ReactManager>
	);
};

export default App;
