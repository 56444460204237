import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {getCart} from "./actions";

export default function Updater() {
    const updateCounter = useSelector(state => state?.cart?.updater);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCart());
    }, [updateCounter])

    return null
}