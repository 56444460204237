import styled from "styled-components";
import { useCallback, useRef, useState } from "react";
import { ArrowDown } from "react-feather";
import useTheme from "../../../../hooks/useTheme";
import { NavLink } from "react-router-dom";
import {logout} from "../../../../states/admin/actions";
import {disconnectWallet} from "../../../../states/auth/actions";
import {useDispatch} from "react-redux";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0 30px;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Title = styled.span`
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 48px;
	border-radius: 12px;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.text2};
	font-size: 1rem;
	font-weight: 400;
	transition: 0.3s ease all;

	&:hover,
	&:focus,
	&:active {
		outline: none;
		text-decoration: none;
	}
`;

const Dropdown = styled.div`
	padding: 0 16px;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	border-radius: 18px;
	background-color: ${({ theme }) => theme.elementBG};
`;

const Collapse = styled.div`
	overflow: hidden;
	max-height: ${({ height }) => `${height}px` || 0};
	display: flex;
	flex-direction: column;

	transition: 0.4s ease all;
`;

const InnerItem = styled(NavLink)`
	padding: 6px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none;
	background-color: transparent;
	min-height: 36px;
	height: 36px;
	border-radius: 12px;
	color: ${({ theme }) => theme.text1};
	font-size: 0.875rem;
	font-weight: 400;
	transition: 0.3s ease all;
	
	&:first-child,
	&:last-child {
		min-height: 48px;
		height: 48px;
	}

	&:first-child {
		padding-top: 18px;
	}

	&:last-child {
		padding-bottom: 18px;
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		outline: none;
		text-decoration: none;
		color: ${({ theme }) => theme.text1};
		font-weight: 700;
	}
`;

const SideDrawerDropdown = (props) => {
	const [open, toggle] = useState(false);
	const [height, setHeight] = useState(0);
	const theme = useTheme();
	const dispatch = useDispatch();

	const header = useRef(null);
	const content = useRef(null);

	const showCollapse = useCallback(() => {
		const contentRect = content?.current;

		if (open) {
			setHeight(0);
		} else {
			setHeight(contentRect?.scrollHeight);
		}

		toggle((show) => !show);
	}, [open]);

	const handleAction = (action) => {
		switch(action) {
			case "logout": {
				if(props?.type === 'admin') {
					dispatch(logout());
				} else {
					dispatch(disconnectWallet());
				}
				break;
			}
		}
		props.onDismiss();
	}


	return (
		<Wrapper>
			<Header onClick={showCollapse} ref={header}>
				<Title>{props.title}</Title>
				<ArrowDown color={theme.text2} size={16} />
			</Header>
			<Collapse height={height}>
				<Dropdown ref={content}>
					{Object.keys(props.items).map((key) => {
						const item = props.items[key];

						return item?.type === 'action' ? (
							<InnerItem
								key={key}
								onClick={handleAction.bind(this, item.action)}
								as={'button'}
							>
								{item.title}
							</InnerItem>
						) : (
							<InnerItem
								exact
								key={key}
								onClick={props.onDismiss}
								to={item.path}
								activeClassName={"active"}
							>
								{item.title}
							</InnerItem>
						);
					})}
				</Dropdown>
			</Collapse>
		</Wrapper>
	);
};

export default SideDrawerDropdown;
