import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import Hammer from 'hammerjs';

import useTheme from "../../../hooks/useTheme";
import EmptyStar from '../../../assets/images/rating/star-empty.svg';
import FullStart from '../../../assets/images/rating/star.svg';
import testimonials from "../../../constants/testimonials";
import Img from "../../../components/Img";
import {useEffect, useMemo, useRef, useState} from "react";

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`

const SectionTitle = styled.span`
  margin-top: 20px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.text1};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`

const Wrapper = styled.div`
  padding-top: 144px;
  padding-bottom: 91px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;


  @media (max-width: 991px) {
    padding-top: 0;
    padding-bottom: 60px;
  }
`

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 21px;
  
  & .custom-rate {
    display: flex;
    align-items: center;
    
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
    
    & > span {
      max-height: 24px;
      padding-right: 16px;
      display: flex !important;
      align-items: flex-start;
      
      &:last-of-type {
        padding-right: 0;
      }
    }
    
    & > p {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    padding-bottom: 12px;
    padding-right: 0;
  }
`

const ReviewDesc = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  padding-top: 3px;
  color: ${({ theme }) => theme.text2};

  @media (max-width: 991px) {
    font-size: 0.75rem;
    line-height: 15px;
  }
`

const Description = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  font-size: .875rem;
  line-height: 17px;
  text-align: justify;
`

const SwiperContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 35px;
  right: -286px;
  top: -70px;
  bottom: 0;
  min-height: 321px;
  height: ${({ dynamicHeight }) => `${dynamicHeight + 100}px`};
  
  @media (max-width: 1199px) {
    right: -120px;
    top: -50px;
  }
  
  @media (max-width: 991px) {
    position: relative;
    left: initial;
    right: initial;
    top: initial;
    bottom: initial;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
    min-height: 400px;
    height: ${({ dynamicHeight }) => `${dynamicHeight + 100}px`};
  }
  
  @media (max-width: 400px) {
    min-height: 450px;
  }
`

const TestimonailBody = styled.div`
  align-self: stretch;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  opacity: 0.8;
  line-height: 1.3;
  text-align: justify;
  margin-bottom: 26px;
  transition: 0.3s ease all;


  @media (max-width: 1199px) {
    font-size: 0.875rem;
    margin-bottom: 16px;
  }
  
  @media (max-width: 991px) {
    font-size: 1rem;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    text-align: center;
  }
`

const TestimonialWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 200px;
  min-width: 100%;
  border-radius: 40px;
  background-image: linear-gradient(98.96deg, rgba(93, 90, 255, 0.12) 1.5%, rgba(255, 90, 159, 0.055875) 39.4%, rgba(63, 225, 167, 0.12) 80.93%, rgba(159, 158, 226, 0.12) 119.63%);
  backdrop-filter: blur(68px);
  padding: 24px 48px 33px;

  @supports not ((-webkit-backdrop-filter:  blur(68px)) or (backdrop-filter: blur(68px))) {
    background-color: ${({ theme }) => theme.popUpBG};
    background-image: none;
    border: 1px solid ${({ theme }) => theme.elementBG};
    backdrop-filter: none;
  }


  @media (max-width: 1199px) {
    align-items: flex-start;
  }

  @media (max-width: 991px) {
    align-items: stretch;
  }
  
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 24px 32px 24px;
  }
  
`

const TestimonialUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 120px;
  padding-right: 15px;
  transition: 0.3s ease all;


  @media (max-width: 1199px) {
    padding-right: 24px;
  }
  
  @media (max-width: 767px) {
    align-items: center;
    margin-bottom: 24px;
  }
`

const TestimonialImage = styled(Img)`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.text2};
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.elementBG};
  transition: 0.3s ease all;


  @media (max-width: 1199px) {
    width: 80px;
    height: 80px;
    margin-bottom: 18px;
  }
`

const TestimonialName = styled.span`
  font-size: .875rem;
  font-weight: 700;
  line-height: 17px;
  color: ${({ theme }) => theme.text1};
  transition: 0.3s ease all;
`

const TestimonialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  transition: 0.3s ease all;


  @media (max-width: 991px) {
    justify-content: space-between;
  }
`


const TestimonailDate = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: .875rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.58;
  transition: 0.3s ease all;
`

const SwiperSlide = styled.div`
  border-radius: 40px;
  overflow: hidden;
  min-height: 200px;
  width: 713px;
  position: absolute;
  pointer-events: ${({ status }) =>
          status === 'active' || status === 'next' || status === 'new-next' ? 'initial' : 'none'
  };
  top: ${({ status }) =>
    status === 'active' ? '121px' :
        status === 'next' ? '60px' :
            status === 'new-next' ? '0' :
                status === 'hidden' ? '-60px' :
                    status === 'prev' ? '181px' : '240px'
    };
  opacity: ${({ status }) =>
    status === 'active' || status === 'next' || status === 'new-next' ? '1' : '0'
    };
  left: ${({ status }) =>
    status === 'active' ? '0' :
        status === 'next' ? '185px' :
            status === 'new-next' ? '370px' :
                status === 'hidden' ? '555px' :
                    status === 'prev' ? '-185px' : '-370px'
    };
  z-index: ${({ status }) =>
    status === 'active' ? '5' :
        status === 'next' ? '4' :
            status === 'new-next' ? '3' :
                status === 'hidden' ? '1' :
                    status === 'prev' ? '6' : '7'
    };
  transition: 0.45s ease all;
  
  & ${TestimonailBody} {
    opacity: ${({ status }) =>
    status === 'active' ? '0.8' :
        status === 'next' ? '0.3' :
            status === 'new-next' ? '0.3' : '0'
    };
  }
  
  & ${TestimonailDate},
  & ${TestimonialImage},
  & ${TestimonialName} {
    opacity: ${({ status }) =>
    status === 'active' ? 1 :
        status === 'next' ? '0.3' :
            status === 'new-next' ? '0.3' : '0'
    };
  }


  @media (max-width: 1199px) {
    width: 600px;
  }
  
  @media (max-width: 991px) {
    width: 100%;
  }


  @media (max-width: 767px) {
    top: ${({ status }) =>
            status === 'active' ? '80px' :
                    status === 'next' ? '40px' :
                            status === 'new-next' ? '0' :
                                    status === 'hidden' ? '-40px' :
                                            status === 'prev' ? '120px' : '160px'
    };
  }
  
`

const SwiperArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 90px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  z-index: 600;
`

let hammerInstance;

const Testimonial = props => {
    const [active, setActive] = useState(0);
    const theme = useTheme();
    const testimonialRef = useRef(null);
    const activeItem = useRef(null);
    const [height, setHeight] = useState(220);

    useEffect(() => {
        if(testimonialRef) {
            hammerInstance = new Hammer(testimonialRef.current)
                .on('swipeleft', () => {
                    setActive(a => {
                        if(a === testimonials.length - 1) {
                            return 0;
                        }
                        return a + 1;
                    });
                })
                .on('swiperight', () => {
                    setActive(a => {
                        if(a === 0) {
                            return testimonials.length - 1;
                        }
                        return a - 1;
                    });
                })
        }

    }, [testimonialRef])

    useEffect(() => {
        window.addEventListener('resize', setHeightHandler)

        return () => {
            window.removeEventListener('resize', setHeightHandler);
        }
    }, [])

    useEffect(() => {
        if(activeItem.current) {
            setHeightHandler();
        }
    }, [activeItem, active])

    const setHeightHandler = () => {
        if(activeItem.current) {
            const rect = activeItem.current?.getBoundingClientRect();
            if(rect?.height) {
                setHeight(rect?.height);
            }
        }
    }

    return (
        <Wrapper>
            <Row>
                <Col xs={12} md={12} lg={4} className={'d-flex flex-column align-items-stretch'}>
                    <Title>Reviews</Title>
                    <div className="d-flex align-items-stretch align-items-lg-center flex-column flex-lg-row">
                        <StarsContainer>
                            <ReactStars
                                edit={false}
                                classNames={'custom-rate'}
                                count={5}
                                value={5}
                                size={20}
                                emptyIcon={<SVG src={EmptyStar} width={24} height={24}/>}
                                filledIcon={<SVG src={FullStart} width={24} height={24}/>}
                                activeColor={theme.primary}
                            />
                        </StarsContainer>
                        <ReviewDesc>Read the 500+ reviews </ReviewDesc>
                    </div>
                    <SectionTitle>Over 500 reviews with an avg 4.5 rating</SectionTitle>
                    <Description>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</Description>
                </Col>
                <Col xs={12} md={12} lg={8}>
                    <SwiperContainer className={'swiper-container'} dynamicHeight={height}>
                        <SwiperArea ref={testimonialRef}/>
                        <div className="swiper-wrapper">
                            {testimonials.map((item, _i) => {
                                let status;
                                if(_i === active) {
                                    status = 'active';
                                } else if(_i === active + 1 || (active === testimonials.length - 1 && _i === 0)) {
                                    status = 'next';
                                } else if(_i === active + 2 || (active === testimonials.length - 2 && _i === 0) || (active === testimonials.length - 1 && _i === 1)) {
                                    status = 'new-next';
                                } else if(_i === active - 1 || (active === 0 && testimonials.length - 1 === _i)) {
                                    status = 'prev';
                                } else if(_i === active - 2 || (active === 0 && testimonials.length - 2 === _i) || (active === 1 && testimonials.length - 1 === _i)) {
                                    status = 'new-prev';
                                } else {
                                    status = 'hidden'
                                }
                                return (
                                    <SwiperSlide status={status} onClick={() => setActive(_i)} className={status} ref={status === 'active' ? activeItem : undefined}>
                                        <TestimonialWrapper>
                                            <TestimonialUser>
                                                <TestimonialImage
                                                    src={item.image}
                                                    alt={item.name}
                                                />
                                                <TestimonialName>{item.name}</TestimonialName>
                                            </TestimonialUser>
                                            <TestimonialContent>
                                                <TestimonailBody>{item.comment}</TestimonailBody>
                                                <TestimonailDate>{item.date}</TestimonailDate>
                                            </TestimonialContent>
                                        </TestimonialWrapper>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    </SwiperContainer>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default Testimonial;