import axios from "axios";

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_NODE_ENV === "development"
            ? "http://localhost:3063/api/v2/"
            : `${process.env.REACT_APP_BACKEND_API}/api/v2/`,
    timeout: 30000,
});

instance.interceptors.response.use(
    res => {
        if(res?.hasOwnProperty('data')) {
            return res?.data;
        }

        return res;
    },
    err => {
        if(err?.response) {
            return Promise.reject(err?.response?.data);
        }

        return Promise.reject(err);
    }
);

export default instance;