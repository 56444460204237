import styled from "styled-components";
import { Row, Col } from 'react-bootstrap';
import {steps} from "../../../constants";
import Img from "../../../components/Img";

const Wrapper = styled.div`
  border-radius: 40px;
  background-image: linear-gradient(125.51deg, rgba(95, 92, 255, 0.12) 29.18%, rgba(58, 54, 223, 0.12) 86.53%);
  margin-bottom: 60px;
`

const SectionInner = styled.div`
  opacity: 0.5;
  transition: 0.3s ease all;
`

const Section = styled(Col)`
  position: relative;
  padding: 52px 61px 16px 37px;
  min-height: 175px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: 0;
    background-image: linear-gradient(190deg, #841BFD 7.5%, rgba(132, 27, 253, 0) 110.87%);
    border-radius: 40px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.82);
    transition: 0.4s ease all;
  }
  
  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
      transform: scale(1);

    }
    
    & > ${SectionInner} {
      opacity: 1;
    }
  } 
`

const InnerRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  margin-right: -5px;
  margin-left: -5px;

`


const SectionTitle = styled.span`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 1.125rem;
`

const SectionDesc = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.text2};
`

const SectionIcon = styled(Img)`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  pointer-events: none;
`


const Steps = props => {
    return (
        <Row>
            <Col xs={12} xl={{ span: 10, offset: 1}}>
                <Wrapper>
                    <InnerRow>
                        {steps.map((item, _i) => {
                            return (
                                <Section
                                    key={`buy-step-${_i}`}
                                    xs={12}
                                    lg={Math.floor(12 / steps.length)}
                                >
                                    <SectionIcon
                                        src={item?.icon}
                                        alt={item?.title}
                                        {...item?.style}
                                    />
                                    <SectionInner className="d-flex flex-column align-items-stretch">
                                        <SectionTitle>{item?.title}</SectionTitle>
                                        <SectionDesc>{item?.description}</SectionDesc>
                                    </SectionInner>
                                </Section>
                            )
                        })}
                    </InnerRow>
                </Wrapper>
            </Col>
        </Row>
    )
}

export default Steps;