import styled from "styled-components";

import { useDarkModeManager } from "../../states/preferences/hooks";

import MoonIcon from "../Icons/Moon";
import SunIcon from "../Icons/Sun";
import useTheme from "../../hooks/useTheme";

const Container = styled.div`
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-bottom: 134px;
`;
const Wrapper = styled.div`
	width: 130px;
	height: 40px;
	border: none;
	border-radius: 18px;
	background-color: transparent;
	background-image: ${({ theme }) => theme.switchBG};
	color: ${({ theme }) => theme.switchText};
	padding: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		box-shadow: none;
		outline: none;
		text-decoration: none;
	}
`;

const Title = styled.span`
	font-size: 0.75rem;
	font-weight: 400;
	padding: 0 4px;
	flex: 1;
	pointer-events: none;
`;

const SwitchButton = styled.div`
	width: 32px;
	height: 32px;
	background-color: ${({ theme }) => theme.switchKey};
	display: flex;
	justify-content: center;
	border-radius: 32px;
	position: relative;
	overflow: hidden;
`;

const SwitchInner = styled.div`
	display: flex;
	flex-direction: column;
	width: 32px;
	height: 64px;
	left: 0;
	top: 0;
	transform: translateY(${({ darkMode }) => (darkMode ? "-32px" : "0")});
	transition: transform 0.3s ease;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
`;

const ThemeToggle = (props) => {
	const [darkMode, toggleDarkMode] = useDarkModeManager();
	const theme = useTheme();

	return (
		<Container>
			<Wrapper onClick={toggleDarkMode}>
				<Title>Switch mode</Title>
				<SwitchButton>
					<SwitchInner darkMode={darkMode}>
						<IconContainer>
							<MoonIcon size={15} fill={theme.switchText} />
						</IconContainer>
						<IconContainer>
							<SunIcon size={22} fill={theme.switchText} />
						</IconContainer>
					</SwitchInner>
				</SwitchButton>
			</Wrapper>
		</Container>
	);
};

export default ThemeToggle;
