import { Switch, Route, Redirect } from "react-router-dom";
import { Suspense } from "react";

import DAORoutes from "./DAORoutes";
import SplashScreen from "./components/SplashScreen";
import ShoppingRoutes from "./ShoppingRoutes";

const Routes = () => {
	return (
		<Suspense fallback={<SplashScreen />}>
			<Switch>
				<Route path={"/dao"} component={DAORoutes} />
				<Route path={'/'} component={ShoppingRoutes}/>
				<Redirect to={"/"} />
			</Switch>
		</Suspense>
	);
};

export default Routes;
