import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { headerRoutes } from "../../../../constants";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { addressShorten } from "../../../../utils/getAddressShorten";
import { useActiveWeb3React } from "../../../../hooks";

const ModifiedJazzicon = styled(Jazzicon)`
	width: 32px;
	height: 32px;
	margin-right: 1rem;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 1199px) {
		display: none;
	}
`;

const List = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Item = styled.li`
	padding: 0 20px;
	height: 56px;
	display: flex;
	align-items: center;
`;

const Anchor = styled.a`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.text3};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px;

	&:hover {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

const StyledLink = styled(NavLink)`
	text-decoration: none;
	outline: none;
	color: ${({ theme }) => theme.text3};
	font-weight: 500;
	font-size: 1rem;
	transition: 0.3s ease all;
	display: block;
	padding: 10px;

	&:hover,
	&.active {
		color: ${({ theme }) => theme.primary};
	}

	&:focus,
	&:active {
		outline: none;
	}
`;

const AccountView = styled.div`
	height: 56px;
	padding: 0 24px;
	background-color: ${({ theme }) => theme.primaryLight};
	color: ${({ theme }) => theme.text1};
	border-radius: 18px;
	display: flex;
	align-items: center;
`;

const AccountAddress = styled.span`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	color: ${({ theme }) => theme.text1};
`;

const Navigation = (props) => {
	const { account } = useActiveWeb3React();
	return (
		<Container>
			<List>
				{Object.keys(headerRoutes).map((key) => {
					const r = headerRoutes[key];

					return (
						<Item>
							<StyledLink exact to={r.path} activeClassName={"active"}>
								{r.title}
							</StyledLink>
						</Item>
					);
				})}
			</List>
			{account ? (
				<Link to={"/dao/connect"}>
					<AccountView>
						<ModifiedJazzicon address={account} />
						<AccountAddress>{addressShorten(account)}</AccountAddress>
					</AccountView>
				</Link>
			) : (
				<Link to={"/dao/connect"}>
					<Button variant={"primary"}>Connect</Button>
				</Link>
			)}
		</Container>
	);
};

export default Navigation;
