import styled from "styled-components";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";

import { config, localStorageKey } from "./config";
import ShoppingPage from "../../components/Global/Page/shopping";
import Card, { ResponsiveCard } from "../../components/Card";
import { APP_NAME } from "../../constants";
import GuideLines from "../../components/Guideline";
import { useActiveWeb3React } from "../../hooks";
import {toast} from "react-hot-toast";

const StyledCard = styled(ResponsiveCard)`
	padding: 8px;

	.card-body {
		padding-top: 0;
	}

	.card-header {
		border-bottom-width: 0;
	}

	@media (max-width: 991px) {
		padding: 0;
	}
`;

const CustomNav = styled(Nav)`
	margin-bottom: 30px;
	min-width: 100%;
	overflow: auto;
	margin-left: -30px !important;
	margin-right: -30px !important;
	border-bottom: 1px solid ${({ theme }) => theme.text3};

	@media (min-width: 768px) {
		margin-left: -10px !important;
		margin-right: -10px !important;
	}
`;

const CustomNavItem = styled(Nav.Item)`
	padding: 0 20px;
	flex: 0 !important;
	white-space: nowrap;

	@media (max-width: 767px) {
		padding: 0 5px;
	}

	&:first-child {
		padding-left: 0;
		@media (max-width: 767px) {
			padding-left: 30px;
		}
	}
	&:last-child {
		padding-right: 0;
		@media (max-width: 767px) {
			padding-right: 30px;
		}
	}
`;

const CustomNavLink = styled(Nav.Link)`
	border-radius: 0 !important;
	color: ${({ theme }) => theme.text1};
	background-color: transparent !important;
	padding: 17px 6px;
	position: relative;
	font-weight: 500;
	font-size: 0.875rem;
	display: flex;
	border-bottom: 3px solid transparent;
	align-items: center;
	justify-content: center;
	transition: all ease 0.3s;

	@media (max-width: 767px) {
		padding: 6px 15px;
		font-size: 1rem;
		min-height: 32px;
	}

	&:hover {
		color: ${({ theme }) => theme.text1} !important;
	}

	&.active {
		color: ${({ theme }) => theme.text1} !important;
		background-color: transparent;
		border-bottom-color: ${({ theme }) => theme.primary};
	}
`;

const InnerCard = styled(Card)`
	.card-body {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 32px;
	}
`;

const TabTitle = styled.h4`
	margin-top: 0;
	margin-bottom: 8px;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 400;
	color: ${({ theme }) => theme.text1};
	text-align: center;
`;

const TabDesc = styled.span`
	font-size: 0.875rem;
	font-weight: 400;
	color: ${({ theme }) => theme.text2};
	text-align: center;
	margin-bottom: 32px;
`;

const ConnectButton = styled(Button)`
	height: 56px;
	width: 100%;
	max-width: 216px;
`;

const Description = styled.div`
	display: flex;
	align-items: center;
`;


const Connect = (props) => {
	const wallet = useActiveWeb3React();

	const activate = (connector, id) => {
		try {
			if(id === 'injected' && !window.ethereum) {
				toast.error("Please install metamask or use DApp browser");
				return false;
			}
			if(!(String(window.ethereum?.chainId) === "0x38" || String(window.ethereum?.chainId) === "0x61")) {
				toast.error("please switch your network to Binance smart chain first");
				return false;
			}
			wallet.activate(connector);
			window.localStorage.setItem(localStorageKey, "1");
		} catch(e) {
			console.log(e);
		}
	};

	return (
		<ShoppingPage title={false} breadcrumb={false}>
			<StyledCard title={"Connect to Wallet"} header={true}>
				<Tab.Container defaultActiveKey={"connector0"}>
					<Row>
						<Col xs={12}>
							<CustomNav
								fill
								variant="pills"
								className={"d-flex flex-row align-items-center flex-nowrap"}
							>
								{config.map((item, _i) => {
									return (
										<CustomNavItem key={"connector" + _i}>
											<CustomNavLink eventKey={"connector" + _i}>{item.title}</CustomNavLink>
										</CustomNavItem>
									);
								})}
							</CustomNav>
						</Col>
						<Col xs={12}>
							<Tab.Content className={"bg-transparent"}>
								{config.map((item, _i) => {
									const GuideLine = GuideLines[item.icon];
									return (
										<Tab.Pane eventKey={"connector" + _i} key={"connector-tab" + _i}>
											<InnerCard>
												<TabTitle>Connect to Wallet</TabTitle>
												<TabDesc>
													Allow {APP_NAME} connect to {item.title} to begin.
												</TabDesc>
												<ConnectButton
													variant={"primary"}
													onClick={() => activate(item.connector, item.connectorId)}
												>
													Connect
												</ConnectButton>
											</InnerCard>
											{item.description && (
												<Description>{GuideLine && <GuideLine />}</Description>
											)}
										</Tab.Pane>
									);
								})}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</StyledCard>
		</ShoppingPage>
	);
};

export default Connect;
