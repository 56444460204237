import styled from "styled-components";
import { Form, InputGroup as Group } from "react-bootstrap";

export const Input = styled(Form.Control)`
	border-radius: 18px;
	background-color: ${({ theme }) => theme.elementBG};
	min-height: 56px;
	padding: 18px 20px;
	color: ${({ theme }) => theme.text1};
	resize: none;
	border: none;
	font-weight: 400;
	font-size: 0.875rem;

	@media (min-width: 768px) {
		font-size: 1rem;
	}

	::placeholder {
		color: ${({ theme }) => theme.text2};
	}

	&:focus,
	&:active,
	&:read-only,
    &:disabled {
		outline: none;
		box-shadow: none;
		color: ${({ theme }) => theme.text1};
		background-color: ${({ theme }) => theme.elementBG};
	}
`;


export const InputGroupPrepend = styled(Group.Prepend)``;

export const InputGroupAppend = styled(Group.Append)``;

export const InputGroupText = styled(Group.Text)`
	background-color: ${({ theme }) => theme.elementBG} !important;
	border-color: transparent !important;
	color: ${({ theme }) => theme.text1};
  
  ${InputGroupPrepend} & {
    padding-left: 2rem;
    padding-right: 0.75rem;
  } 
  
  ${InputGroupAppend} & {
    padding-right: 2rem;
    padding-left: 0.75rem;
  } 
`;

export const InputGroupFormControl = styled(Input)`
	border-color: transparent !important;
	background-color: ${({ theme }) => theme.elementBG} !important;
	color: ${({ theme }) => theme.text1} !important;

	::placeholder {
		color: ${({ theme }) => theme.text3};
	}
`;

export const InputGroup = styled(Group)``;
