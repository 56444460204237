import { createReducer } from "@reduxjs/toolkit";
import {connectWallet, disconnectWallet, setLimits, updateProfile} from "./actions";

const initialState = {
    accessToken: null,
    isLoggedIn: false,
    profile: null,
    limits: undefined,
    balance: null
};

export default createReducer(initialState, builder =>
    builder
        .addCase(disconnectWallet, (state, action) => {
            state.accessToken = null;
            state.isLoggedIn = false;
            state.profile = null;
        })
        .addCase(connectWallet, (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.isLoggedIn = true;
            state.profile = action.payload.profile;
        })
        .addCase(updateProfile, (state, action) => {
            state.profile = action.payload;
        })
        .addCase(setLimits, (state, action) => {
            state.limits = action.payload.limits;
            state.balance = action.payload.balance;
        })
)