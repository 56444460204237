export const APP_NAME: string = process.env.REACT_APP_NAME || "Shopping DAPP";
export const CHAIN_ID: number = Number(process.env.REACT_APP_CHAIN_ID) || 56;
export const KOVAN_NETWORK = process.env.REACT_APP_INFURA_NETWORK_URL;
export const KOVAN_WEBSOCKET = process.env.REACT_APP_INFURA_NETWORK_WSS;

export const BSC_NODE_1: string = process.env.REACT_APP_NODE_1 || "";
export const BSC_NODE_2: string = process.env.REACT_APP_NODE_2 || "";
export const BSC_NODE_3: string = process.env.REACT_APP_NODE_3 || "";

export const INITIAL_DARK_MODE: boolean = process.env.REACT_APP_INITIAL_DARK_MODE === "true";


export const TYPING_INTERVAL: number = 300;
