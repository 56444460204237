import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Img from "../../../components/Img";
import {partners} from "../../../constants";

const Wrapper = styled.div`
  background-image: linear-gradient(90.9deg, rgba(132, 27, 253, 0.49) -6.55%, rgba(87, 83, 252, 0.49) 109.22%);
  border-radius: 40px;
  padding: 16px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 16px;


  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`

const Image = styled(Img)`
  padding: 28px;
`

const Partners = props => {
    return (
        <Row>
            <Col xs={12}>
                <Title>Partner Companies</Title>
            </Col>
            <Col xs={12}>
                <Wrapper>
                    {partners.map((item, _i) => {
                        return (
                            <Image
                                src={item?.image}
                                key={`partner-${_i}`}
                            />
                        )
                    })}
                </Wrapper>
            </Col>
        </Row>
    )
}

export default Partners;