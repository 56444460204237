import { createReducer } from "@reduxjs/toolkit";
import {updateLogin, updateLogout} from "./actions";

const initialState = {
    accessToken: null,
    isLoggedIn: false,
    profile: null
}

export default createReducer(initialState, builder =>
    builder
        .addCase(updateLogin, (state , action) => {
            state.isLoggedIn = true;
            state.accessToken = action.payload.accessToken;
            state.profile = action.payload.admin;
        })
        .addCase(updateLogout, (state, action) => {
            state.isLoggedIn = false;
            state.accessToken = null;
            state.profile = null
        })
)