import {useEffect} from "react";

import {useActiveWeb3React} from "../../hooks";
import {useAuthentication} from "./hooks";

export default function Updater() {
    const { account } = useActiveWeb3React();
    const [isLoggedIn, handleAuthorization] = useAuthentication()

    useEffect(() => {
        handleAuthorization()
    }, [account])

    return null
}