import { createAction } from '@reduxjs/toolkit';
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setVouchers = createAction('vouchers/set');
export const updateVouchers = createAction("vouchers/update");

export const deleteVoucherFromList = createAction('vouchers/deleteItem');


export const getAllVouchers = (params) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.getAllVouchers({
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
                params
            })

            if(res) {
                if(params.page === 1) {
                    dispatch(setVouchers(res?.data?.data));
                } else {
                    dispatch(updateVouchers(res?.data?.data));
                }
                return res?.results;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const getVoucher = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.getVoucher(id, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                return res?.data;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const deleteVoucher = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.deleteVoucher(id, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                dispatch(deleteVoucherFromList(id));
                toast.success("Voucher code deleted successfully!")
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const createVoucher = (body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.createVoucher(body, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                toast.success("Voucher code created successfully!")
                return res?.data;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const updateVoucher = (id, body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const adminAccessToken = store.admin.accessToken;

        try {
            const res = await api.updateVoucher(id, body, {
                headers: {
                    'Authorization': `Bearer ${adminAccessToken}`
                },
            })

            if(res) {
                toast.success("Voucher code updated successfully!")
                return res?.data;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}