import React, { useMemo } from "react";
import styled, {
	ThemeProvider as StyledComponentsThemeProvider,
	createGlobalStyle,
	css,
	DefaultTheme,
} from "styled-components";
import { Text, TextProps } from "rebass";
import { useIsDarkMode } from "../states/preferences/hooks";
import { Colors } from "./styled";

const MEDIA_WIDTHS = {
	upToExtraSmall: 576,
	upToSmall: 768,
	upToMedium: 992,
	upToLarge: 1200,
};

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
	(accumulator, size) => {
		(accumulator as any)[size] = (a: any, b: any, c: any) => css`
			@media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
				${css(a, b, c)}
			}
		`;
		return accumulator;
	},
	{}
) as any;

const white = "#FFFFFF";
const black = "#000000";

export function colors(darkMode?: boolean): Colors {
	return {
		// base
		white,
		black,

		// Text
		text1: darkMode ? white : "#202020",
		text2: darkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(32, 32, 32, 0.6)",
		text3: darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(32, 32, 32, 0.3)",
		text4: darkMode ? "rgba(255, 255, 255, 0.15)" : "rgba(32, 32, 32, 0.15)",

		bg1: "rgba(149, 47, 136, 0.25)",
		bg2: "rgba(167, 50, 152, 0.22)",
		bg3: "rgba(69, 56, 152, 0.28)",
		bg4: "rgba(69, 56, 152, 0.26)",

		bodyBG: darkMode ? "linear-gradient(164.33deg, #272129 4.39%, #2E1458 110.62%)" : white,
		bodyColor: darkMode ? "rgb(75, 38, 89)" : "rgb(227, 225, 239)",
		sidebarBG: darkMode
			? "linear-gradient(177.05deg, rgba(255, 255, 255, 0.11) -0.96%, rgba(255, 255, 255, 0.05) 97.69%)"
			: "linear-gradient(164.33deg, #290770 4.39%, #CEB3FB 110.62%)",
		sidebarBorder: darkMode ? "rgba(255, 255, 255, 0.4)" : "transparent",
		sidebarActiveBG: "linear-gradient(90deg, rgba(255, 255, 255, 0.38) -281.12%, rgba(255, 255, 255, 0) 159.91%)",
		elementBG: darkMode ? "rgba(255, 255, 255, 0.06)" : "rgba(32, 32, 32, 0.06)",
		highlightBG: darkMode ? "rgba(255, 255, 255, 0.08)" : "rgba(32, 32, 32, 0.08)",
		primaryGrad: "linear-gradient(57.78deg, #7F30DE 27.5%, #F46EF2 72.65%)",
		modalBG: darkMode ? "rgba(32, 32, 32, 0.8)" : "rgba(255, 255, 255, 0.8)",
		popUpBG: darkMode ? "rgba(32, 32, 32, 1)" : "rgba(255, 255, 255, 1)",
		sideDrawerBG: "linear-gradient(164.33deg, #290770 4.39%, #CEB3FB 110.62%)",
		dropdownBG: "linear-gradient(147.33deg, rgba(93, 90, 255, 0.12) 1.97%, rgba(255, 90, 159, 0.055875) 38.61%, rgba(63, 225, 167, 0.12) 78.75%, rgba(159, 158, 226, 0.12) 116.15%)",
		footerBG: "linear-gradient(90.03deg, rgba(93, 90, 255, 0.12) 0.03%, rgba(255, 90, 159, 0.055875) 32.1%, rgba(63, 225, 167, 0.12) 67.24%, rgba(159, 158, 226, 0.12) 99.98%)",
		productBG: "linear-gradient(228.67deg, rgba(255, 255, 255, 0.55) -13.27%, rgba(151, 139, 221, 0.764687) 45.45%, #DCAFAF 111.54%)",
		heroBG: "linear-gradient(125.51deg, rgba(95, 92, 255, 0.6) 29.18%, rgba(58, 54, 223, 0.6) 86.53%)",

		switchKey: "rgba(93, 90, 255, .5)",
		switchBG: "linear-gradient(90deg, rgba(255, 255, 255, 0.38) -281.12%, rgba(255, 255, 255, 0) 159.91%)",
		switchText: "rgba(255, 255, 255, 0.5)",

		sidebarBorderWidth: darkMode ? "1px" : "0",

		// other
		red1: "#D55555",
		green1: "#4AC8AA",
		yellow1: "#F3BA2F",
		blue1: "#87dce1",

		danger: "#D55555",
		warning: "#F3BA2F",
		success: "#2AB0B0",
		primary: "#5D5AFF",
		primaryLight: "rgba(93, 90, 255, .15)",
		secondary: "#7F30DE",
		secondaryLight: "#841BFD",
		tertiary: "#F46EF2",
	};
}

export function theme(darkMode?: boolean): DefaultTheme {
	return {
		...colors(darkMode),

		grids: {
			sm: 8,
			md: 12,
			lg: 24,
		},

		//shadows
		shadow1: "#87dce1",

		// media queries
		mediaWidth: mediaWidthTemplates,

		// css snippets
		flexColumnNoWrap: css`
			display: flex;
			flex-flow: column nowrap;
		`,
		flexRowNoWrap: css`
			display: flex;
			flex-flow: row nowrap;
		`,
	};
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
	const darkMode = useIsDarkMode();

	const themeObject = useMemo(() => theme(darkMode), [darkMode]);

	return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
	color: ${({ color, theme }) => (theme as any)[color]};
`;

export const TYPE = {
	main(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"text2"} {...props} />;
	},
	link(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"primary"} {...props} />;
	},
	black(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"text1"} {...props} />;
	},
	white(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"white"} {...props} />;
	},
	body(props: TextProps) {
		return <TextWrapper fontWeight={400} fontSize={16} color={"text1"} {...props} />;
	},
	largeHeader(props: TextProps) {
		return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
	},
	mediumHeader(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
	},
	subHeader(props: TextProps) {
		return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
	},
	small(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
	},
	blue(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"primary"} {...props} />;
	},
	yellow(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"yellow1"} {...props} />;
	},
	darkGray(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"text3"} {...props} />;
	},
	gray(props: TextProps) {
		return <TextWrapper fontWeight={500} color={"bg3"} {...props} />;
	},
	italic(props: TextProps) {
		return <TextWrapper fontWeight={500} fontSize={12} fontStyle={"italic"} color={"text2"} {...props} />;
	},
	error({ error, ...props }: { error: boolean } & TextProps) {
		return <TextWrapper fontWeight={500} color={error ? "red1" : "text2"} {...props} />;
	},
};

export const FixedGlobalStyle = createGlobalStyle`

html, body { font-family: "Inter", "system-ui" !important; }

@supports (font-variation-settings: normal) {
  html, body { font-family: "Inter var", "system-ui" !important; }
}

html,body {
  min-height: 100vh;
}


.modal-scrollbar-measure{position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll}
`;

export const ThemedGlobalStyle = createGlobalStyle`
	.react-loading-skeleton {
		background-color: ${({ theme }) => theme.elementBG} !important;
		background-image: linear-gradient(
			90deg,
			'transparent',
			${({ theme }) => theme.elementBG},
			'transparent'
		) !important;
	}
    html,body {
      color: ${({ theme }) => theme.text1};
    }
    body {
      background: ${({ theme }) => theme.bodyBG};
      color: ${({ theme }) => theme.text1};
    }
    
    .modal-backdrop {
      background: ${({ theme }) => theme.text2};
    }
    
    @media (max-width: 991px) {
      body {
        background: ${({ theme }) => theme.bodyColor};
      }
    }
`;
