import {createAction} from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import api from "../../http/api";

export const setOrders = createAction('adminOrders/set');
export const updateOrders = createAction('adminOrder/update');
export const deleteOrderFromList = createAction('adminOrder/delete');

export const getOrders = (params) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.admin?.accessToken;

        if(!jwt) {
            return false;
        }


        try {
            const res = await api.getAllOrders({
                headers: {
                    "Authorization": `Bearer ${jwt}`
                },
                params
            })

            if(res) {
                if(params.page === 1) {
                    dispatch(setOrders(res?.data?.data));
                } else {
                    dispatch(updateOrders(res?.data?.data));
                }
                return res?.results;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");
            return false;
        }
    }
}

export const getOrder = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.admin?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.getOrder(id, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                return res?.data?.order;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const deleteOrder = (id) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.admin?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.deleteOrder(id, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                dispatch(deleteOrderFromList(id));
                toast.success("Order deleted successfully!")
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}

export const updateOrderStatus = (id, body) => {
    return async (dispatch, getState) => {
        const store = getState();
        const jwt = store.admin?.accessToken;

        if(!jwt) {
            return false;
        }

        try {
            const res = await api.updateOrderStatus(id, body, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
            })

            if(res) {
                toast.success("Order status updated successfully!")
                return true;
            }

            return false;
        } catch(e) {
            toast.error(e?.message || e?.data?.message || "An error occurred, please try again.");

            return false;
        }
    }
}