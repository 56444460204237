export default [
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
    {
        image: require('../assets/images/testimonial/user.png').default,
        name: "Andruo Anderson",
        comment: "The Shopping Dapp team provides great service and has become irreplaceable in our programmatic line up. Other vendors promise 100% fill, but YieldLift actually delivers! They are responsive, bring innovative new ideas to increase monetization, and always provide timely reporting and payment, which is huge for us.",
        date: "20/05/2021"
    },
]