import { combineReducers } from "@reduxjs/toolkit";

import preferences from "./preferences/reducer";
import governance from "./governance/reducer";
import application from "./application/reducer";
import burn from "./burn/reducer";
import lists from "./lists/reducer";
import mint from "./mint/reducer";
import multicall from "./multicall/reducer";
import swap from "./swap/reducer";
import transactions from "./transactions/reducer";
import user from "./user/reducer";
import admin from "./admin/reducer";
import voucher from "./voucher/reducer";
import auth from "./auth/reducer";
import address from "./address/reducer";
import shop from "./shop/reducer";
import cart from "./cart/reducer";
import orders from "./orders/reducer";
import adminOrders from "./adminOrders/reducer";

const rootReducer = combineReducers({
	preferences,
	governance,
	application,
	burn,
	lists,
	mint,
	multicall,
	swap,
	transactions,
	user,
	admin,
	voucher,
	auth,
	address,
	shop,
	cart,
	orders,
	adminOrders
});

export const PERSIST_KEYS: string[] = ["preferences", "admin", "auth", "shop", "cart"];

export default rootReducer;
