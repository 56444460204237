import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useCallback } from "react";

import { AppState, AppDispatch } from "../index";
import { updateDarkMode } from "./actions";

export function useIsDarkMode(): boolean {
	const { darkMode } = useSelector<AppState, { darkMode: boolean }>(
		({ preferences: { darkMode } }) => ({
			darkMode,
		}),
		shallowEqual
	);

	return darkMode || false;
}

export function useDarkModeManager(): [boolean, () => void] {
	const dispatch = useDispatch<AppDispatch>();
	const darkMode = useIsDarkMode();

	const toggleSetDarkMode = useCallback(() => {
		dispatch(updateDarkMode({ userDarkMode: !darkMode }));
	}, [darkMode, dispatch]);

	return [darkMode, toggleSetDarkMode];
}
